import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { colors } from "../../styles/global.styles";

const breakpoints = [420, 565, 768, 992, 1200, 1380];

const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

const hmq = breakpoints.map((bp) => `@media (min-height: ${bp}px)`);

export const Body = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  marginRight: "10px",
  marginLeft: "10px",
  padding: "0 1em",
  fontFamily: "Roboto, sans-serif",

  [mq[0]]: {},
});

export const Root = styled.div({
  position: "absolute",

  backgroundColor: colors.primaryBackground,

  height: "100%",
  width: "auto",

  [mq[0]]: {
    height: "fit-content",
    width: "fit-content",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
    paddingBottom: "5vh",
  },
});

export const textBody = css({
  maxWidth: "40em",
  padding: "3em",
  paddingTop: "0",
  [mq[0]]: {
    textAlign: "left",
  },
});

export const title = css({});

export const icon = css({
  height: "auto",
  width: "25%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10%",

  [mq[0]]: {
    height: "auto",
    width: "15%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "9%",
  },
});

export const text = css({
  color: colors.primaryTextColor,
  marginTop: "1em",
  marginBottom: "0",
});

export const textDark = css({
  color: colors.secondaryTextColor,
});

export const textSecondary = css({
  color: colors.primaryAccentColor,
});

export const options = css({
  display: "flex",
  justifyContent: "center",
  color: colors.primaryTextColor,

  [mq[0]]: {},
  [mq[2]]: { gap: "4vw" },
  [mq[3]]: {
    gap: "3vw",
  },
});

export const dropdown = css({
  marginRight: "10px",

  [mq[0]]: {
    width: "12vw",
  },
  [mq[2]]: {
    width: "8vw",
  },
  [mq[3]]: {
    width: "2vw",
  },
});

export const phonenumber = css({
  [mq[0]]: { width: "19vw" },
  [mq[2]]: {
    width: "15vw",
  },
  [mq[3]]: {
    width: "10vw",
  },
  [mq[4]]: { width: "8vw" },
});

export const checkbox = css({
  display: "flex",
  alignItems: "center",
  color: colors.primaryTextColor,
  justifyContent: "center",
  flexShrink: 1,
  flexWrap: "nowrap",
  flexDirection: "row",
});

export const select = css({
  backgroundColor: colors.tertiaryBackground,
});

export const otp = css({
  display: "flex",
  justifyContent: "center",
  width: "250px",
  marginLeft: "250px",
});

export const textStep2 = css({
  [mq[0]]: {
    marginLeft: "4.5vw",
    marginRight: "4.5vw",
    marginBottom: "0",
  },
});

export const buttonStyle = css({
  backgroundColor: colors.primaryAccentColor,
  color: colors.primaryTextColor,
  borderColor: colors.primaryAccentColor,

  "&:disabled": {
    opacity: ".5",
    backgroundColor: colors.primaryAccentColor,
    color: colors.primaryTextColor,
    borderColor: colors.primaryAccentColor,
  },
});

export const deviceNameCss = css({
  width: "75%",

  marginLeft: "12.5%",
  marginTop: "1vh",

  [mq[0]]: {
    width: "50%",

    marginLeft: "25%",
  },
});

export const resend = css({
  color: colors.primaryAccentColor,
  background: "none",
  border: "none",
});

export const otpInputBoxStying = css({
  color: colors.primaryTextColor,
  backgroundColor: colors.secondaryBackground,
  width: "10vw",
  height: "10vw",
  fontSize: "8vw",
  textAlign: "center",

  [mq[0]]: { width: "7vw", height: "7vw", fontSize: "5vw" },
  [mq[2]]: {
    width: "5vw",
    height: "5vw",
    fontSize: "3vw",
  },
  [mq[3]]: {
    width: "5vw",
    height: "5vw",
    fontSize: "3vw",
  },
  [mq[4]]: { width: "3vw", height: "3vw", fontSize: "2vw" },
});

export const inputFieldsCSS = css({
  color: colors.primaryTextColor,
  backgroundColor: colors.secondaryBackground,
  border: "none",
  borderRadius: "10px",
  height: "3em",
  textAlign: "left",
  accentColor: colors.secondaryBackground,
  webkitAppearance: "none",
  padding: "0 20px",
  "::-webkit-inner-spin-button": {
    webkitAppearance: "none",
    appearance: "none",
    margin: 0,
  },
  "::-webkit-outer-spin-button": {
    webkitAppearance: "none",
    appearance: "none",
    margin: 0,
  },
  "::placeholder": {
    textAlign: "center",
  },
});

export const selectCSS = css({
  background:
    "url(data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBzdHJva2U9IiNmZmZmZmYiPgoNPGcgaWQ9IlNWR1JlcG9fYmdDYXJyaWVyIiBzdHJva2Utd2lkdGg9IjAiLz4KDTxnIGlkPSJTVkdSZXBvX3RyYWNlckNhcnJpZXIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgoNPGcgaWQ9IlNWR1JlcG9faWNvbkNhcnJpZXIiPiA8cGF0aCBkPSJNNS43MDcxMSA5LjcxMDY5QzUuMzE2NTggMTAuMTAxMiA1LjMxNjU4IDEwLjczNDQgNS43MDcxMSAxMS4xMjQ5TDEwLjU5OTMgMTYuMDEyM0MxMS4zODA1IDE2Ljc5MjcgMTIuNjQ2MyAxNi43OTI0IDEzLjQyNzEgMTYuMDExN0wxOC4zMTc0IDExLjEyMTNDMTguNzA4IDEwLjczMDggMTguNzA4IDEwLjA5NzYgMTguMzE3NCA5LjcwNzA4QzE3LjkyNjkgOS4zMTY1NSAxNy4yOTM3IDkuMzE2NTUgMTYuOTAzMiA5LjcwNzA4TDEyLjcxNzYgMTMuODkyN0MxMi4zMjcxIDE0LjI4MzMgMTEuNjkzOSAxNC4yODMyIDExLjMwMzQgMTMuODkyN0w3LjEyMTMyIDkuNzEwNjlDNi43MzA4IDkuMzIwMTYgNi4wOTc2MyA5LjMyMDE2IDUuNzA3MTEgOS43MTA2OVoiIGZpbGw9IiNmZmZmZmYiLz4gPC9nPgoNPC9zdmc+) no-repeat",
  backgroundSize: "20%",
  backgroundPositionX: "95%",
  backgroundPositionY: "50%",
  appearance: "none",
  color: colors.primaryTextColor,
  backgroundColor: colors.secondaryBackground,
  border: "none",
  borderRadius: "10px",
  height: "3em",
  textAlign: "center",
  textAlignLast: "center",
  fontSize: "1em",
  // width: "15vw",
  "::placeholder": {
    textAlign: "center",
  },
});

export const termsCSS = css({
  display: "flex",
  gap: "0.5vw",
  marginLeft: "1vw",
  color: colors.primaryTextColor,
  justifyContent: "center",
  fontSize: "0.8em",
});

export const registerButton = css({
  textDecoration: "none",
  border: "none",
  borderRadius: "10px",
  fontFamily: "'Roboto', sans-serif",
  marginTop: "1em",
  padding: "1vh 1vw",
  backgroundColor: colors.primaryAccentColor,
  color: colors.primaryTextColor,
  ":disabled": {
    opacity: "0.5",
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const step2RegisterButton = css({
  [hmq[0]]: {
    marginTop: "1.5em",
  },
});

export const checkedCSS = css({
  backgroundColor: colors.secondaryBackground,
  height: "5vh",
  width: "5vw",
  maxWidth: "1em",
  border: "1px",
  borderColor: colors.secondaryTextColor,
  color: colors.primaryTextColor,

  [mq[0]]: { height: "3vh", width: "3vw" },
  [mq[2]]: {
    height: "3vh",
    width: "3vw",
  },
  [mq[3]]: {
    height: "2vh",
    width: "2vw",
  },
  [mq[4]]: { height: "1.5em", width: "1.5em" },
});

export const provisioningBody = css({
  background: "url('/assets/provisioning/Login-BG-Graphic.svg') center 0 black",
  backgroundAttachment: "fixed",
  fontFamily: "'Roboto', sans-serif",
  height: "100vh",
  "& p, & label p, & .stepBody label p, & p.smallText": {
    fontSize: "0.875em",
    lineHeight: "1.5",
  },
  [mq[0]]: {
    "& > div": {
      borderRadius: "40px",
      maxWidth: "90%",
      padding: "2vh 0",
    },
    "& > #SecondStep, & > #ThirdStep, & > #LastStep": {
      padding: "5vh 2vh",
    },
    "& > #LastStep": {
      width: "90%",
    },
  },
  [mq[1]]: {
    "& > #LastStep": {
      width: "565px",
    },
  },
  [mq[2]]: {
    "& > #SecondStep": {
      minWidth: "712px",
    },
  },
  [mq[4]]: {
    "& > #SecondStep": {
      backgroundImage: "url('/assets/provisioning/No_Blocked _Graphic.svg')",
      backgroundPosition: "92% center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "520px",
      paddingRight: "600px",
      width: "1200px",
    },
  },
});

export const stepBody = css({
  textAlign: "left",
  width: "100%",
  "& .smallText": {
    fontSize: "0.75em",
  },

  "& .miniHeader": {
    fontSize: "1em",
    fontWeight: "bold",
    marginBottom: "0.5em",
  },
  "& .policyLinks > div": {
    fontSize: "0.875em",
    fontWeight: "bold",
    justifyContent: "flex-start",
    marginLeft: "0",
    marginRight: "0",
  },
  "& .actionContainer": {
    marginTop: "2em !important",
    [hmq[0]]: {
      marginTop: "4em",
    },
  },
  "& label p": {
    fontSize: "0.9375em",
  },
  "& .fieldLabel": {
    fontSize: "0.75em",
    letterSpacing: "1.8px",
    paddingBottom: "1.5em",
    textTransform: "uppercase",
  },
});

export const verseLogo = css({
  height: "55px",
});

export const stepHeader = css({
  fontSize: "1.5em",
  fontWeight: "500",
  margin: "0.7em 0",
  textAlign: "left",
});

export const step1Body = css({
  [mq[0]]: {
    padding: "0",
  },
});

export const areaCodeBox = css({
  minWidth: "7em",
});

export const areaCodeField = css({
  minWidth: "7em",
});
export const reloginDeviceNameLabel = css({
  minWidth: "7em",
  whiteSpace: "nowrap",
  textAlign: "right",
});

export const step2PhoneNumber = css({
  width: "80%",
});

export const otpInputsBox = css({
  margin: "2vh 0",
  "& > div": {
    justifyContent: "center",
  },
  "& input": {
    backgroundColor: colors.secondaryBackground,
    borderRadius: "10px",
    height: "55px",
    padding: "0",
    textAlign: "center",
    width: "55px",
  },
});

export const resendCodeText = css({
  color: colors.secondaryTextColor,
  fontSize: "0.9em",
  fontWeight: "bold",
});

export const step3ContinueButton = css({
  marginTop: "2vh",
});

export const mobileNumberFields = css({
  gap: "0%",
  [mq[0]]: {
    gap: "5%",
  },
  [mq[5]]: {
    gap: "0%",
  },
});

export const closeButton = css({
  color: colors.primaryTextColor,
  position: "absolute",
  right: "20px",
  top: "20px",
});

export const OdienceLogo = css({
  color: colors.primaryTextColor,
  fontFamily: "Figtree, sans-serif",
  fontWeight: "lighter",
  letterSpacing: "1rem",
  fontSize: "1rem",
  "@media (min-width: 768px)": {
    letterSpacing: "1.5rem",
    fontSize: "1.5rem",
  },
  "@media (min-width: 1024px)": {
    letterSpacing: "2rem",
    fontSize: "2rem",
  },
  alignSelf: "center",
});
